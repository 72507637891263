import { Component, OnInit } from '@angular/core';

import { AuthService } from '@app/core/auth.service';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { BeforeInstallPromptChoices, WindowService } from '@app/utils/window.service';

import { AnalyticsService } from './core/analytics.service';

@Component({
  selector: 'om-app',
  templateUrl: './app.component.html',
  providers: [],
})
// Component controller
export class MyOneApp implements OnInit {
  constructor(
    private windowService: WindowService,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.authService.init();

    this.windowService.appInstallChoiceStream.subscribe(choiceResult => {
      if (choiceResult.outcome === BeforeInstallPromptChoices.Dismissed) {
        this.analyticsService.appInstallBannerDismissed();
      } else {
        this.analyticsService.appInstallBannerAccepted();
      }
    });

    this.launchDarklyService.featureFlag$(FeatureFlags.APPLY_TYPOGRAPHY_CHANGE, false).subscribe(flagVariant => {
      if (flagVariant) {
        this.windowService.getBody().classList.add('typography');
      } else {
        this.windowService.getBody().classList.remove('typography');
      }
    });
  }
}
